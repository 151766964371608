import React from "react"
import Image from "gatsby-image/withIEPolyfill"
import { mdiArrowRight } from "@mdi/js"

// Project components
import {
  AspectRatio,
  Box,
  Button,
  FlexGrid,
  Link,
  RichText,
  Wrapper,
} from "components"

const CallOutSection = ({ data, id }) => {
  return (
    <>
      {/* We only render the section if it has props set */}
      {(data.bodyContent || data.buttonLabel || data.buttonTo) && (
        <Wrapper id={id}>
          <FlexGrid
            content={[
              {
                width: ["100%", "50%"],
                children: (
                  <Box>
                    {/* Content */}
                    {data.bodyContent && (
                      <RichText
                        content={{
                          html: data.bodyContent,
                        }}
                        size={600}
                      />
                    )}

                    {/* Button Link */}
                    {(data.buttonLabel || data.buttonTo) && (
                      <Button
                        as={Link}
                        to={data.buttonTo}
                        iconAfter={mdiArrowRight}
                        intent="primary"
                        appearance="primary"
                        mt={["layout.4", "layout.6"]}
                      >
                        {data.buttonLabel}
                      </Button>
                    )}
                  </Box>
                ),
              },
              {
                width: ["100%", "50%"],
                children: (
                  <AspectRatio ratio="4:3" borderRadius={16} bg="wash">
                    {data.image && (
                      <Image
                        fluid={data.image.fluid}
                        alt={data.image.alt}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                        }}
                        objectFit="cover"
                        objectPosition="center"
                      />
                    )}
                  </AspectRatio>
                ),
              },
            ]}
            alignItems="center"
            gutterY={[7]}
          />
        </Wrapper>
      )}
    </>
  )
}

export default CallOutSection
