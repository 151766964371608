import React from "react"

import { Box, Heading, RichText, Wrapper } from "components"

const ValuesSection = ({ data, id }) => {
  return (
    <>
      {/* We only render the section if it has props set */}
      {(data.title || data.bodyContent) && (
        <Wrapper id={id} marginTop={[-32, -48]}>
          <Box>
            {data.title && (
              <Heading
                size={800}
                m={0}
                mb={["layout.2", null, "layout.3"]}
                color="brand.primary"
              >
                {data.title}
              </Heading>
            )}

            {/* Content */}
            {data.bodyContent && (
              <RichText
                content={{
                  html: data.bodyContent,
                }}
                size={500}
              />
            )}
          </Box>
        </Wrapper>
      )}
    </>
  )
}

export default ValuesSection
