import React from "react"

import { Heading, RichText, Wrapper } from "components"

const MissionSection = ({ data, id }) => {
  return (
    <>
      {/* We only render the section if it has props set */}
      {(data.title || data.cards) && (
        <Wrapper id={id}>
          {/* Title */}
          {data.title && (
            <Heading
              size={800}
              mb={["layout.2", null, "layout.3"]}
              color="brand.primary"
            >
              {data.title}
            </Heading>
          )}

          {/* Content */}
          {data.bodyContent && (
            <RichText
              content={{
                html: data.bodyContent,
              }}
              size={500}
            />
          )}
        </Wrapper>
      )}
    </>
  )
}

export default MissionSection
