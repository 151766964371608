import React from "react"
import { Link as ScrollLink } from "react-scroll"
import { mdiArrowDown } from "@mdi/js"

// Project components
import { Button, Heading, RichText, Split } from "components"

const CONTENT_START_ID = "about-callOut"

const IntroSection = ({ data, id }) => {
  return (
    <>
      {/* We only render the section if it has props set */}
      {(data.title || data.bodyContent) && (
        <Split id={id} image={data.image}>
          {/* Title */}
          {data.title && <Heading size={900}>{data.title}</Heading>}

          {/* Content */}
          {data.bodyContent && (
            <RichText
              content={{
                html: data.bodyContent,
              }}
              size={500}
              mt={["layout.4", "layout.8"]}
            />
          )}

          <ScrollLink smooth={true} to={CONTENT_START_ID}>
            <Button
              as="div"
              iconAfter={mdiArrowDown}
              intent="primary"
              appearance="primary"
              height={[40, 48]}
              mt="layout.5"
            >
              Learn More
            </Button>
          </ScrollLink>
        </Split>
      )}
    </>
  )
}

export default IntroSection
