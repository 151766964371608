import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { mergePrismicPreviewData } from "gatsby-source-prismic"

import { SEO } from "components"

// Page sections
import IntroSection from "./_introSection"
import MissionSection from "./_missionSection"
import TeamSection from "./_teamSection"
import ValuesSection from "./_valuesSection"
import FullImageSection from "./_fullImageSection"
import CallOutSection from "./_callOutSection"

function AboutUsPage() {
  const staticData = useStaticQuery(graphql`
    {
      prismicAbout {
        data {
          seo_title {
            text
          }
          seo_description {
            text
          }
          seo_image {
            url
            alt
          }
          hero_image {
            fluid(maxWidth: 1200) {
              ...GatsbyPrismicImageFluid
            }
            alt
          }
          hero_title {
            text
          }
          hero_description {
            html
          }
          mission_title {
            text
          }
          description {
            html
          }
          team_title {
            text
          }
          team_description {
            html
          }
          team_button_label
          team_button_link {
            slug
            type
            url
          }
          team_image {
            fluid(maxWidth: 1900) {
              ...GatsbyPrismicImageFluid
            }
            alt
          }
          values_title {
            text
          }
          values {
            html
          }
          sustainability_hero_image {
            fluid(maxWidth: 1900) {
              ...GatsbyPrismicImageFluid
            }
            alt
          }
          sustainability_hero_image {
            fluid(maxWidth: 1900) {
              ...GatsbyPrismicImageFluid
            }
            alt
          }
          sustainability_secondary_image {
            fluid(maxWidth: 600) {
              ...GatsbyPrismicImageFluid
            }
            alt
          }
          sustainability_description {
            html
          }
          sustainability_button_text
          sustainability_button_link {
            slug
            type
            url
          }
        }
      }
    }
  `)
  const previewData =
    typeof window !== "undefined" && window.__PRISMIC_PREVIEW_DATA__
  const finalData = mergePrismicPreviewData({ staticData, previewData })
  return (
    <>
      <SEO
        title={
          finalData.prismicAbout.data.seo_title &&
          finalData.prismicAbout.data.seo_title.text
        }
        description={
          finalData.prismicAbout.data.seo_description &&
          finalData.prismicAbout.data.seo_description.text
        }
        image={
          finalData.prismicAbout.data.seo_image &&
          finalData.prismicAbout.data.seo_image.url
        }
        imageAlt={
          finalData.prismicAbout.data.seo_image &&
          finalData.prismicAbout.data.seo_image.alt
        }
      />
      {/* ============ INTRO ============ */}
      <IntroSection
        id="about-intro"
        data={{
          image:
            finalData.prismicAbout.data.hero_image &&
            finalData.prismicAbout.data.hero_image.fluid &&
            finalData.prismicAbout.data.hero_image,
          title:
            finalData.prismicAbout.data.hero_title &&
            finalData.prismicAbout.data.hero_title.text,
          bodyContent:
            finalData.prismicAbout.data.hero_description &&
            finalData.prismicAbout.data.hero_description.html,
        }}
      />
      {/* ============ MISSION ============ */}
      <MissionSection
        id="about-mission"
        data={{
          title:
            finalData.prismicAbout.data.mission_title &&
            finalData.prismicAbout.data.mission_title.text,
          bodyContent:
            finalData.prismicAbout.data.description &&
            finalData.prismicAbout.data.description.html,
        }}
      />
      {/* ============ VALUES ============ */}
      <ValuesSection
        id="about-values"
        data={{
          title:
            finalData.prismicAbout.data.values_title &&
            finalData.prismicAbout.data.values_title.text,
          bodyContent:
            finalData.prismicAbout.data.values &&
            finalData.prismicAbout.data.values.html,
        }}
      />
      {/* ============ TEAM SECTION ============ */}
      <TeamSection
        id="about-team"
        data={{
          title:
            finalData.prismicAbout.data.team_title &&
            finalData.prismicAbout.data.team_title.text,
          bodyContent:
            finalData.prismicAbout.data.team_description &&
            finalData.prismicAbout.data.team_description.html,
          buttonLabel: finalData.prismicAbout.data.team_button_label,
          buttonTo: finalData.prismicAbout.data.team_button_link,
          image:
            finalData.prismicAbout.data.team_image &&
            finalData.prismicAbout.data.team_image.fluid &&
            finalData.prismicAbout.data.team_image,
        }}
      />
      {/* ============ FULL IMAGE ============ */}
      <FullImageSection
        id="about-fullImage"
        data={{
          image:
            finalData.prismicAbout.data.sustainability_hero_image &&
            finalData.prismicAbout.data.sustainability_hero_image.fluid &&
            finalData.prismicAbout.data.sustainability_hero_image,
        }}
      />
      {/* ============ CALL OUT ============ */}
      <CallOutSection
        id="about-callOut"
        data={{
          image:
            finalData.prismicAbout.data.sustainability_secondary_image &&
            finalData.prismicAbout.data.sustainability_secondary_image.fluid &&
            finalData.prismicAbout.data.sustainability_secondary_image,
          bodyContent:
            finalData.prismicAbout.data.sustainability_description &&
            finalData.prismicAbout.data.sustainability_description.html,
          buttonLabel: finalData.prismicAbout.data.sustainability_button_text,
          buttonTo: finalData.prismicAbout.data.sustainability_button_link,
        }}
      />
    </>
  )
}

export default AboutUsPage
