import React from "react"
import Image from "gatsby-image/withIEPolyfill"
import { mdiArrowRight } from "@mdi/js"

import {
  AspectRatio,
  Box,
  Button,
  Divider,
  FlexGrid,
  Heading,
  Link,
  RichText,
  Wrapper,
} from "components"

const TeamSection = ({ data, id }) => {
  return (
    <>
      {(data.title || data.cards) && (
        <>
          {/* We only render the section if it has props set */}
          <Divider />
          <Wrapper id={id} pt={0} pb={0}>
            <FlexGrid
              content={[
                {
                  width: ["100%", "50%"],
                  children: (
                    <Box>
                      {/* Title */}
                      {data.title && (
                        <Heading
                          size={800}
                          mb={["layout.2", null, "layout.3"]}
                          color="brand.primary"
                        >
                          {data.title}
                        </Heading>
                      )}

                      {/* Content */}
                      {data.bodyContent && (
                        <RichText
                          content={{
                            html: data.bodyContent,
                          }}
                          size={500}
                        />
                      )}

                      {/* Button Link */}
                      {data.buttonLabel && (
                        <Button
                          as={Link}
                          to={data.buttonTo}
                          iconAfter={mdiArrowRight}
                          intent="primary"
                          appearance="primary"
                          mt={["layout.4", "layout.6"]}
                        >
                          {data.buttonLabel}
                        </Button>
                      )}
                    </Box>
                  ),
                },
                {
                  width: ["100%", "50%"],
                  children: (
                    <AspectRatio ratio="4:3" borderRadius={16} bg="wash">
                      {data.image && (
                        <Image
                          fluid={data.image.fluid}
                          alt={data.image.alt}
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                          }}
                          objectFit="cover"
                          objectPosition="center"
                        />
                      )}
                    </AspectRatio>
                  ),
                },
              ]}
              alignItems="center"
              gutterY={[7]}
            />
          </Wrapper>
          <Divider />
        </>
      )}
    </>
  )
}

export default TeamSection
